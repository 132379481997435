// Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/firestore"

/***
 * 
 * @NOTE HOW TO USE FIRESTORE
 * 
 ************************************************************************
 * 
 * How to GET data from firestore:
 * 
 * const getUsers = async () => {
 *      const data = await db.collection('Users').get().then((qs) => {
 *           qs.forEach((doc) => {
 *               let d = doc.data()
 *               console.log(d)
 *           })
 *       })
 *   }
 * 
 ************************************************************************
 * 
 * How to PUT data to firestore:
 * 
 * const userRef = db.collection('Users').add({
 *    uid: userCredentials.user.uid,
 *    username: username,
 *    email: email,
 *    saved_jobs: [],
 * })
 *
 ************************************************************************
 *
 * How to UPDATE data to firestore:
 * 
 * const ref = await db.collection('Users').doc(id).update({
 *     name: "Ethan Shealey"
 * })
 * 
 */

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDrrBmcvInvQlOlaEpN13fylArhbolg_DY",
  authDomain: "recipes-a97a3.firebaseapp.com",
  projectId: "recipes-a97a3",
  storageBucket: "recipes-a97a3.appspot.com",
  messagingSenderId: "345617588046",
  appId: "1:345617588046:web:c39540a8e86b361781c81f"
};


let app

if(firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
}
else {
    app = firebase.app()
}

const auth = firebase.auth()
const db = firebase.firestore()

export { auth, db }