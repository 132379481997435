import * as React from 'react'
import Recipe from '../Models/Recipe'
import { Link } from 'react-router-dom'

const RecipeItem = ({ recipe, setRecipe }: { recipe: Recipe, setRecipe: React.Dispatch<React.SetStateAction<Recipe | undefined>> }) => {
  return (
    <div className="post-preview">
        <Link to={`/recipe?name=${recipe.name}&id=${recipe.id}`} onClick={() => setRecipe(recipe)}>
            <h2 className="post-title">{ recipe.name }</h2>
        </Link>
        <p className="post-meta">
            Posted by
            <a style={{ color: "rgba(0,0,0,.85)" }}> Ethan Shealey </a>
            on { new Date(recipe.date_modified).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
        </p>
    </div>
  )
}

export default RecipeItem