import * as React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import { HashRouter as Router } from "react-router-dom"
import "./styles.css"

createRoot(document.getElementById('root')!).render(
    <Router basename="/">
        <App />
    </Router>
)
