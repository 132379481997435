import * as React from 'react'
import Recipe from '../Models/Recipe'
import RecipePostHeader from './RecipePostHeader'
import RecipePostBody from './RecipePostBody'
import { useHistory } from 'react-router-dom'
import RecipeDeleteModal from './RecipeDeleteModal'
import { db } from "../firebase"

const RecipePost = ({ recipe, recipes, setRecipe, admin }: { recipe: Recipe | undefined, recipes: Recipe[] | undefined, setRecipe: React.Dispatch<React.SetStateAction<Recipe | undefined>>, admin: boolean }) => {

  React.useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const history = useHistory();
  const [ showModal, setShowModal ] = React.useState(false)

  const updateRecipe = () => {
    history.push(`/update?recipe=${recipe!.id}`)
  }

  const deleteRecipe = () => {
    db.collection("recipes").doc(recipe!.id).delete().then(() => window.location.reload())
    history.push(`/`)
  }

  return (
    <>
      <RecipeDeleteModal showModal={showModal} setShowModal={setShowModal} deleteRecipe={deleteRecipe} />
      <RecipePostHeader name={recipe?.name} date={recipe?.date_modified} />
      <RecipePostBody 
        admin={admin}
        ingredients={recipe?.ingredients} 
        instructions={recipe?.instructions} 
        cook_time={recipe?.cook_time} 
        source={recipe?.source} 
        updateRecipe={updateRecipe} 
        setShowModal={setShowModal}
       />
    </>
  )

}

export default RecipePost