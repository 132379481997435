import * as React from 'react'
import Recipe from '../Models/Recipe'
import Header from './Header'
import Spinner from './Spinner'
import RecipeList from './RecipeList'

const Home = ({ recipes, setRecipe }: { recipes: Recipe[], setRecipe: React.Dispatch<React.SetStateAction<Recipe | undefined>> }) => {
  return (
    <>
    <Header />
      { recipes.length === 0 ? <Spinner /> : <RecipeList recipes={recipes} setRecipe={setRecipe} /> }
    </>
  )
}

export default Home