import * as React from 'react'

const RecipeDeleteModal = ({ showModal, setShowModal, deleteRecipe }: { showModal: boolean, setShowModal: Function, deleteRecipe: Function }) => {
  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,.5)", position: "fixed", display: showModal ? "flex": "none", justifyContent: "center", alignItems: "center", overflow: "hidden", zIndex: "1" }}>
        <div style={{ width: "60vw", height: "auto", backgroundColor: "#fff", zIndex: "99", position: "fixed", padding: "15px" }}>
            <h1 style={{ width: "100%", textAlign: "center" }}>Delete?</h1>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <button className="btn btn-primary" style={{ marginRight: "8px" }} onClick={() => setShowModal(false)}>Cancel</button>
                <button className="btn btn-danger" style={{ marginLeft: "8px" }} onClick={() => deleteRecipe()}>Delete</button>
            </div>
        </div>
    </div>
  )
}

export default RecipeDeleteModal