import * as React from 'react'
import { db } from "../firebase"
import { BsTrash } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import "firebase/firestore"
import Recipe from '../Models/Recipe';

const useQuery = () => new URLSearchParams(useLocation().search)

const Add = ({ admin }: { admin: boolean }) => {

  /****
   * BACKGROUND GENERATION
   */

   const [ background, setBackground ] = React.useState<number>()

   React.useEffect(() => {
    if(!admin) history.push("/")
    setBackground(generateBackground())
    const id: string | null = query.get('recipe');
    if(id) {
      db.collection('recipes').doc(id).get().then((rec: any) => {
        const recipe: Recipe = rec.data()

        // set name
        setRecipeName(recipe.name)

        // reset ingredient list and set ingredients
        setIngredients([])
        recipe.ingredients.forEach((ing: string) => {
          setIngredients(past => [ ...past, ing ])
        })

        // reset instruction list and set steps
        setInstructions([])
        recipe.instructions.forEach((ins: string) => {
          setInstructions(past => [ ...past, ins ])
        })

        // set cook time
        setCookTime(recipe.cook_time)

        // set source
        setSource(recipe.source)
      })
    }
   }, [])
 
   const generateBackground = () => {
     return Math.floor(Math.random() * 14) + 1
   }
 
   /****
    * END BACKGROUND GENERATION
    */

   const history = useHistory();
   const query = useQuery();
   const [ recipeName, setRecipeName ] = React.useState<string>('')
   const [ ingredients, setIngredients ] = React.useState<string[]>([''])
   const [ instructions, setInstructions ] = React.useState<string[]>([''])
   const [ cookTime, setCookTime ] = React.useState<string>('')
   const [ source, setSource ] = React.useState<string>('')

   const handleIngredientRemove = (idx: number) => {
    const tempIngredients = [ ...ingredients ]
    tempIngredients.splice(idx, 1)
    setIngredients([ ...tempIngredients ])
   }

   const handleUpdateIngredient = (idx: number, content: string) => {
    const tempIngredients = [ ...ingredients ]
    tempIngredients[idx] = content
    setIngredients(_ => [ ...tempIngredients ])
   }

   const handleInstructionRemove = (idx: number) => {
    const tempInstructions = [ ...instructions ]
    tempInstructions.splice(idx, 1)
    setInstructions([ ...tempInstructions ])
   }

   const handleUpdateInstruction = (idx: number, content: string) => {
    const tempInstructions = [ ...instructions ]
    tempInstructions[idx] = content
    setInstructions(_ => [ ...tempInstructions ])
   }

   const submit = () => {
    console.log(new Date())
    const recipe: Recipe = {
      "name": recipeName,
      "ingredients": ingredients,
      "instructions": instructions,
      "cook_time": cookTime,
      "source": source,
      "date_modified": `${new Date().toLocaleString("default", { year: "numeric" })}-${new Date().toLocaleString("default", { month: "2-digit" })}-${new Date().toLocaleString("default", { day: "2-digit" })}`
    }
    if(query.get('recipe')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const id: string | undefined = query.get('recipe') !== null ? query.get('recipe') : undefined;
      //delete recipe['date_modified']
      const ref = db.collection('recipes').doc(id).update(recipe).then(() => window.location.reload())
      history.push(`/recipe?name=${recipe.name}&id=${id}`)
    }
    else {
      const ref = db.collection("recipes").add(recipe).then(() => window.location.reload())
      history.push(`/`)
    }
   }

    return (
      <>
        <header className="masthead" style={{ backgroundImage: `url("./images/bg${background}.jpg")`, backgroundPosition: 'center' }}>
          <div className="container position-relative px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-7">
                    <div className="site-heading">
                        <h1>Add a Recipe</h1>
                    </div>
                </div>
            </div>
          </div>
        </header>
        <article className="mb-4">
          <div className="container px-4 px-lg-5">
              <div className="row gx-4 gx-lg-5 justify-content-center">
                  <div className="col-md-10 col-lg-8 col-xl-7">
                          <h2>Recipe Name</h2>
                          <div className="form-floating">
                              <input value={recipeName} onChange={(e) => setRecipeName(e.target.value)} className="form-control" id="name" name="recipe_name" type="text" placeholder="Enter your recipe name..." data-sb-validations="required" />
                              <label htmlFor="name">Recipe Name</label>
                              <div className="invalid-feedback" data-sb-feedback="name:required">A recipe name is required.</div>
                          </div>
                          <br />
                          <br />
                          <h2>Ingredients</h2>
                          <i className="subtitle">To make a header, put a '*' at the start of the line</i>
                          <div id="ingredients" style={{ marginTop: "-35px" }}>
                            {
                              [...Array(ingredients.length)].map((_, i) => (
                                <p>
                                  <div className="control-group">
                                    <div className="form-group floating-label-form-group controls">
                                      <div className="form-floating" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                        <input value={ingredients[i]} onChange={(e) => handleUpdateIngredient(i, e.target.value)} type="text" id="Ingredient" className="form-control" placeholder='Ingredient' />
                                        <label htmlFor="Ingredient">Ingredient</label>
                                        { i !== 0 && <BsTrash style={{ marginTop: '35px' }} className="trash" onClick={() => handleIngredientRemove(i)} /> }
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              ))
                            }
                            <button className="btn btn-secondary text-uppercase" onClick={() => { setIngredients(ing => [ ...ing, '' ]) }}>Add Ingredient</button>
                          </div>
                          <br />
                          <br />
                          <h2>Instructions</h2>
                          <i className="subtitle">To make a header, put a '*' at the start of the line</i>
                          <div id="instructions" style={{ marginTop: "-35px" }}>
                            {
                              [...Array(instructions.length)].map((_, i) => (
                                <p>
                                  <div className="control-group">
                                    <div className="form-group floating-label-form-group controls">
                                      <div className="form-floating" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                        <input value={instructions[i]} onChange={(e) => handleUpdateInstruction(i, e.target.value)} type="text" id="Instruction" className="form-control" placeholder='Ingredient' />
                                        <label htmlFor="Instruction">Step</label>
                                        { i !== 0 && <BsTrash style={{ marginTop: '35px' }} className="trash" onClick={() => handleInstructionRemove(i)} /> }
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              ))
                            }
                            <button className="btn btn-secondary text-uppercase" onClick={() => { setInstructions(ins => [ ...ins, '' ]) }}>Add Step</button>
                          </div>
                          <br />
                          <br />
                          <h2>Cook Time</h2>
                          <div className="form-floating">
                              <input value={cookTime} onChange={(e) => setCookTime(e.target.value)} className="form-control" id="name" name="recipe_name" type="text" placeholder="Enter your recipe name..." />
                              <label htmlFor="name">Cook Time</label>
                          </div>
                          <br />
                          <h2>Source</h2>
                          <div className="form-floating">
                              <input value={source} onChange={(e) => setSource(e.target.value)} className="form-control" id="name" name="recipe_name" type="text" placeholder="Enter your recipe name..." />
                              <label htmlFor="name">Source</label>
                          </div>
                          <br />
                          <button className="btn btn-primary" onClick={submit}>{ query.get('recipe') ? 'Update' : 'Add' } Recipe</button>
                  </div>
              </div>
          </div>
        </article>
              {/* {
                [...Array(ingredients.length)].map((ing, i) => (
                  <p>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls">
                        <div className="form-floating" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <input value={ingredients[i]} onChange={(e) => updateIngredient(i, e.target.value)} type="text" id="Ingredient" className="form-control" placeholder='Ingredient' />
                          <label htmlFor="Ingredient">Ingredient</label>
                          <BsTrash style={{ marginTop: '35px' }} className="trash" onClick={() => handleRemove(i)} />
                        </div>
                      </div>
                    </div>
                  </p>
                )) }
              }
              <button className="btn btn-primary" onClick={() => { setIngredients(ing => [ ...ing, '' ]) }}>Add Ingredient</button> */}
    </>
  )
}

export default Add