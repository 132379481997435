import * as React from 'react'

const Login = ({ login }: { login: Function }) => {

  const [ email, setEmail ] = React.useState<string>('')
  const [ password, setPassword ] = React.useState<string>('')

  return (
    <>
      <header className="masthead" style={{ backgroundImage: `url(./images/banner.jpg)` }}>
        <div className="container position-relative px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-7">
                    <div className="site-heading">
                        <h1>Login</h1>
                    </div>
                </div>
            </div>
        </div>
      </header>
      <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <div className="form-floating">
                  <input onChange={(e) => setEmail(e.target.value)} className="form-control" id="name" type="text" name="username" placeholder="Enter email..." data-sb-validations="required" />
                  <label htmlFor="name">Email</label>
              </div>
              <div className="form-floating">
                  <input onChange={(e) => setPassword(e.target.value)} className="form-control" id="pass" type="password" name="password" placeholder="Enter password..." data-sb-validations="required" />
                  <label htmlFor="pass">Password</label>
              </div>
              <br />
              <button className="btn btn-primary" onClick={() => login(email, password)}>Login</button>
            </div>
          </div>
        </div>
        <br /><br />
      </>
  )
}

export default Login