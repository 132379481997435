import * as React from 'react'

const RecipePostHeader = ({ name, date }: { name: string | undefined, date: string | undefined }) => {
 
  const [ background, setBackground ] = React.useState<number>()

  React.useEffect(() => {
    setBackground(generateBackground())
  }, [])

  const generateBackground = () => {
    return Math.floor(Math.random() * 14) + 1
  }

  return (
    <header className="masthead" style={{ backgroundImage: `url("./images/bg${background}.jpg")`, backgroundPosition: 'center' }}>
            <div className="container position-relative px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <div className="post-heading">
                            <h1>{ name }</h1>
                            <span className="meta">
                                Posted by
                                <a href="#!"> Ethan Shealey </a>
                                on { new Date(date!).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
  )
}

export default RecipePostHeader