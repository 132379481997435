import * as React from 'react'
import { BsClock, BsLink45Deg } from "react-icons/bs"

const RecipePostBody = ({ admin, ingredients, instructions, cook_time, source, updateRecipe, setShowModal }: { admin: boolean, ingredients: string[] | undefined, instructions: string[] | undefined, cook_time: string | undefined, source: string | undefined, updateRecipe: Function, setShowModal: Function }) => {
  return (
    <article className="mb-4">
        <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-7">
                    <h2>Ingredients</h2>
                    <hr className="my-4" />
                    <ul>
                        {
                            ingredients?.map((ing) => (
                                <>{ ing[0] === '*' ? <p style={{ marginLeft: "-20px", marginBottom: "-1px" }}><b>{ing.slice(1)}</b></p> : <li>{ing}</li> }</>
                            ))
                        }
                    </ul>
                    <h2 style={{ marginTop: "20px" }}>Instructions</h2>
                    <hr className="my-4" />
                    <ol>
                        {
                            instructions?.map((ins) => (
                                <>{ ins[0] === '*' ? <p style={{ marginLeft: "-20px", marginBottom: "-1px" }}><b>{ins.slice(1)}</b></p> : <li>{ins}</li> }</>
                            ))
                        }
                    </ol>
                    <hr className="my-4" />
                    <div className="bottom">
                        <div className="source">
                            <BsLink45Deg style={{ marginRight: "10px", marginTop: "2px" }} />{ source ? <a href={source} target="_blank">Source</a> : 'No Source' }
                        </div>
                        <div className="time">
                            <BsClock style={{ marginRight: "10px", marginTop: "2px" }} />{ cook_time }
                        </div>
                    </div>
                    { 
                        admin &&
                        <p style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <button className="btn btn-primary text-uppercase" style={{ marginRight: "8px" }} onClick={() => updateRecipe(true)}>UPDATE</button>  
                            <button className="btn btn-danger text-uppercase" style={{ marginLeft: "8px" }} onClick={() => setShowModal(true)}>DELETE</button>
                        </p>
                    }
                </div>
            </div>
        </div>
    </article>
  )
}

export default RecipePostBody