import * as React from 'react'

const Header = () => {
  return (
    <header className="masthead" style={{ backgroundImage: `url("./images/banner.jpg")`, backgroundPosition: 'center' }}>
        <div className="container position-relative px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-7">
                    <div className="site-heading">
                        <h1>Recipes</h1>
                        <i className="subheading">straight to the point</i>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header