import * as React from 'react'
import Recipe from '../Models/Recipe'
import RecipeList from './RecipeList'
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../firebase"

const useQuery = () => new URLSearchParams(useLocation().search)

const Search = ({ recipes, setRecipe }: { recipes: Recipe[], setRecipe: React.Dispatch<React.SetStateAction<Recipe | undefined>> }) => {

  /****
   * BACKGROUND GENERATION
   */

  const [ background, setBackground ] = React.useState<number>()

  React.useEffect(() => {
    setBackground(generateBackground())
  }, [])

  const generateBackground = () => {
    return Math.floor(Math.random() * 14) + 1
  }

  /****
   * END BACKGROUND GENERATION
   */

  let history = useHistory();
  const query = useQuery();

  const [ search, setSearch ] = React.useState<string>('')
  const [ results, setResults ] = React.useState<Recipe[]>([])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onSearch()
    }, 500)
    return () => clearTimeout(timer)
  }, [search, recipes])

  React.useEffect(() => {
    const q: string | null = query.get('q')
    if(q) {
      setSearch(s => q)
    }
  }, [])


  const onSearch = (q: string | undefined = undefined) => {

    history.push(`/search?q=${search}`)
    
    // @TODO: REPLACE WITH PROPER SEARCH
    if(search !== '') {
      const tempRecipes: Recipe[] = []
      recipes.forEach((re: Recipe) => {
        if(re.name.toLowerCase().includes(search.toLowerCase()) || 
          re.ingredients.find(r => r.toLowerCase().includes(search.toLowerCase())) || 
          re.instructions.find(r => r.toLowerCase().includes(search.toLowerCase()))) {
            tempRecipes.push(re)
          }  
      })
      setResults(_ => [ ...tempRecipes ])
    }
    
  }

  return (
    <>
      <header className="masthead" style={{ backgroundImage: `url("./images/bg${background}.jpg")`, backgroundPosition: 'center' }}>
        <div className="container position-relative px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-7">
                  <div className="site-heading">
                      <h1>Search</h1>
                      <span className="subheading">Search by keyword</span>
                  </div>
              </div>
          </div>
        </div>
      </header>
      <div className='container'>
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
              <p>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls">
                    <div className="form-floating">
                      <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" id="search" className="form-control" placeholder='Search...' />
                      <label htmlFor="search">Search</label>
                    </div>
                  </div>
                </div>
              </p>
              {/* <button className="btn btn-primary" onClick={onSearch}>Search</button> */}
            {/* <hr /> */}

            {
              results.length === 0 && <p><i style={{ color: "gray", paddingBottom: "25px" }}>No Results</i></p>
            }

          </div>
        </div>
      </div>
      { results.length !== 0 && <RecipeList recipes={results} setRecipe={setRecipe} /> }
    </>
  )
}

export default Search