import * as React from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Nav from "./Components/Nav";
import RecipeList from "./Components/RecipeList";
import { auth, db } from './firebase';
import Recipe from "./Models/Recipe";
import Spinner from "./Components/Spinner";
import {
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  useHistory
} from "react-router-dom";
import Home from "./Components/Home";
import RecipePost from "./Components/RecipePost";
import Search from "./Components/Search";
import Login from "./Components/Login";
import usePersistentData  from './Hooks/PersistData'
import Add from "./Components/Add";

const useQuery = () => new URLSearchParams(useLocation().search)

export default () => {

  const history = useHistory();
  const query = useQuery();
  const [ admin, setAdmin ] = usePersistentData('admin', false);
  const [ recipes, setRecipes ] = React.useState<Recipe[]>([]);
  const [ recipe, setRecipe ] = React.useState<Recipe>();

  React.useEffect(() => {
    getRecipes();
    window.scrollTo(0,0)
  }, [])

  React.useEffect(() => {
    const id: string | null = query.get('id');
    if(id) setRecipe(_ => recipes.find(r => r.id === id));
  }, [recipes])

  const login = (email: string, pass: string) => {
    auth.signInWithEmailAndPassword(email, pass)
      .then(() => {
        setAdmin((_: any) => true)
        history.push("/")
      })
  }

  const logout = () => {
    auth.signOut()
    setAdmin(false)
  }

  const getRecipes = async () => {
    await db.collection('recipes').get().then((qs) => {
      const r: Recipe[] | any = []
      qs.forEach((doc) => {
        r.push({ ...doc.data(), 'id': doc.id })
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      r.sort((a: Recipe, b: Recipe) =>  new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime());
      setRecipes(_ => [ ...r ]);
    })
  }

  history.listen(() => {
    window.scrollTo(0,0)
  })

  return (
    <Switch>
      <>
        <Nav admin={admin} logout={logout} />
        <Route exact path="/" render={() => <Home recipes={recipes} setRecipe={setRecipe} />} />
        <Route path="/recipe" render={() => <RecipePost recipe={recipe} recipes={recipes} setRecipe={setRecipe} admin={admin} />} />
        <Route path="/search" render={() => <Search recipes={recipes} setRecipe={setRecipe} />} />
        { admin ? 
          <>
            <Route path="/add" render={() => <Add admin={admin} />} /> 
            <Route path="/update" render={() => <Add admin={admin} />} /> 
          </>
          : 
          <Route path="/login" render={() => <Login login={login} />} /> 
        }
        <Footer />
      </>
    </Switch>
  );
}
