import * as React from 'react'
import { Link } from "react-router-dom"

const Nav = ({ admin, logout }: { admin: boolean, logout: Function }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
        <div className="container px-4 px-lg-5">
            <Link to="/" className="navbar-brand" >Recipes | ethanshealey.com</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ms-auto py-4 py-lg-0">
                    <li className="nav-item"><Link to="/" className="nav-link px-lg-3 py-3 py-lg-4">Home</Link></li>
                    <li className="nav-item"><Link to="/search" className="nav-link px-lg-3 py-3 py-lg-4">Search</Link></li>
                    { admin ? 
                        <>
                            <li className="nav-item"><Link to="/add" className="nav-link px-lg-3 py-3 py-lg-4">Add Recipe</Link></li> 
                            <li className="nav-item"><Link to="/" onClick={() => logout()} className="nav-link px-lg-3 py-3 py-lg-4">Logout</Link></li> 
                        </>
                        : 
                        <li className="nav-item"><Link to="/login" className="nav-link px-lg-3 py-3 py-lg-4">Login</Link></li> 
                    }
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default Nav