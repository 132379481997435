import * as React from 'react'
import RecipeItem from './RecipeListItem'
import Recipe from '../Models/Recipe'

const RecipeList = ({ recipes, setRecipe }: { recipes: Recipe[], setRecipe: React.Dispatch<React.SetStateAction<Recipe | undefined>> }) => {

  const [ recipesShown, setRecipesShown ] = React.useState(5);

  const showMoreRecipes = () => {
    setRecipesShown(s => s + 5);
  }

  return (
    <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-7">
                <>
                  {
                    recipes.slice(0, recipesShown).map((recipe, i) => (
                      <>
                        <RecipeItem key={recipe.id} recipe={recipe} setRecipe={setRecipe} />
                        { i < recipes.length - 1 && <hr className="my-4" /> }
                      </>
                    ))
                  }
                  { 
                    recipes.length > recipesShown && 
                    <div className="d-flex justify-content-end mb-4">
                      <div className="btn btn-primary text-uppercase" onClick={showMoreRecipes}>More Recipes →</div>
                    </div> 
                  }
                </>
              </div>
          </div>
      </div>
  )
}

export default RecipeList